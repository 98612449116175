<template>
  <el-drawer
    title="我是标题"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    width="80%"
  >
    <el-transfer
      v-model="dataList"
      @change="handleChange"
      filterable
      :titles="['选择角色', '已经配置的角色']"
      :button-texts="['到左边', '到右边']"
      :data="data"
      :props="{
      key: 'roleCode',
      label: 'roleName'
    }"
    ></el-transfer>
    <el-button type="primary" @click="addFormButton()">确 定</el-button>
  </el-drawer>
</template>
<script>
import { listroleApi } from "@/api/system/role/role.js";
import { addProductRoeApi } from "@/api/system/product/product.js";
import { getBindRoleByProductIdApi } from "@/api/system/role/role.js";
export default {
  data() {
    return {
      drawer: false,
      direction: "rtl",
      //未选择这边
      data: [],
      dataList: ['commonTest'],
      commodityCode: ""
    };
  },
  methods: {
    show(commodityCode) {
      this.drawer = true;
      this.commodityCode = commodityCode;
      this.listroleButton();
      this.getBindRoleByProductIdButton();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          this.clearn();
          done()
        })
        .catch(_ => {});
    },
    clearn(){
      this.dataList=[],
      this.data=[],
      this.drawer=false;
      this.$parent.selectDataListButton();
    },
    handleChange(value, direction, movedKeys) {
      console.log("1" + value, movedKeys);
      console.log("2" + direction);
      console.log("3" + this.dataList);
    },
    addFormButton() {
      //传入的数据
      var addRoleFrom = {
        commodityCode: this.commodityCode,
        roleList: Array.from(this.dataList)
      };
      addProductRoeApi(addRoleFrom).then(res => {
          this.clearn()
      });
    },
    listroleButton() {
      listroleApi().then(res => {
        this.data = res.result.records;
      });
    },
    getBindRoleByProductIdButton() {
      getBindRoleByProductIdApi({ commodityCode: this.commodityCode }).then(res => {
        var roleCodeList = res.result.map(function(vo){
            return vo.roleCode;
        })
        this.dataList=roleCodeList
      });
    }
  }
};
</script>
<style scoped>
</style>